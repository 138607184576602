import Button, { ButtonAppearance, ButtonScale } from 'bloko/blocks/button';
import HoverTip from 'bloko/blocks/drop/Tip/HoverTip';
import { EyeScaleMediumCrossedFalse, EyeScaleMediumCrossedTrue, IconColor } from 'bloko/blocks/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { UserType } from 'lux/models/userType';
import { useCardContainerClassNames } from 'src/components/VacancySearchItem/hooks/useCardContainerClassNames';
import BlacklistMenu from 'src/components/VacancyView/BlacklistMenu';
import { BlacklistStatus } from 'src/components/VacancyView/useBlacklistMenu';
import translation from 'src/components/translation';
import useIsClient from 'src/hooks/useIsClient';
import useMagritte from 'src/hooks/useMagritte';
import { useSelector } from 'src/hooks/useSelector';

import styles from './styles.less';

const TrlKeys = {
    [BlacklistStatus.Empty]: 'searchvacancymap.close',
    [BlacklistStatus.BlacklistVacancy]: 'pageControls.BlacklistRemoveVacancy',
    [BlacklistStatus.BlacklistEmployer]: 'pageControls.BlacklistRemoveEmployer',
};

interface BlacklistButtonProps {
    vacancyId: number;
    employerId?: number;
    isAnonymousVacancy: boolean;
}

const BlacklistButton: TranslatedComponent<BlacklistButtonProps> = ({
    trls,
    vacancyId,
    employerId,
    isAnonymousVacancy,
}) => {
    const isMagritte = useMagritte();
    const { wideCardContainerClassName, narrowCardContainerClassName } = useCardContainerClassNames();
    const isFavoritesPage = useSelector(({ router }) =>
        router.location.pathname.startsWith('/applicant/favorite_vacancies')
    );
    const isClient = useIsClient();
    const userType = useSelector((state) => state.userType);

    if (isFavoritesPage || userType !== UserType.Applicant) {
        return null;
    }

    const renderMenu: (params: { status: BlacklistStatus; isLoading: boolean; onClick: () => void }) => JSX.Element = ({
        status,
        onClick,
    }) => {
        if (isMagritte) {
            return <></>;
        }

        return (
            <span className={styles.serpItemControlsItem}>
                <div className={wideCardContainerClassName}>
                    <HoverTip host={isClient ? document.body : null} render={() => trls[TrlKeys[status]]} flexible>
                        <Button
                            aria-label={trls[TrlKeys[status]]}
                            appearance={ButtonAppearance.Flat}
                            data-qa={status === BlacklistStatus.Empty ? 'vacancy__blacklist-show-add' : undefined}
                            onClick={onClick}
                        >
                            <div className="fit-icon-in-button-container">
                                {status === BlacklistStatus.Empty ? (
                                    <EyeScaleMediumCrossedTrue initial={IconColor.Gray50} />
                                ) : (
                                    <EyeScaleMediumCrossedFalse initial={IconColor.Gray50} />
                                )}
                            </div>
                        </Button>
                    </HoverTip>
                </div>

                <div className={narrowCardContainerClassName}>
                    <div className={styles.blacklistMobileContainer}>
                        <Button
                            aria-label={trls[TrlKeys[status]]}
                            appearance={ButtonAppearance.Flat}
                            scale={ButtonScale.Large}
                            data-qa={status === BlacklistStatus.Empty ? 'vacancy__blacklist-show-add' : undefined}
                            onClick={onClick}
                        >
                            <div className="fit-icon-in-button-container">
                                {status === BlacklistStatus.Empty ? (
                                    <EyeScaleMediumCrossedTrue initial={IconColor.Gray50} />
                                ) : (
                                    <EyeScaleMediumCrossedFalse initial={IconColor.Gray50} />
                                )}
                            </div>
                        </Button>
                    </div>
                </div>
            </span>
        );
    };

    return (
        <BlacklistMenu
            vacancyId={vacancyId}
            employerId={employerId}
            isAnonymousVacancy={isAnonymousVacancy}
            render={renderMenu}
        />
    );
};

export default translation(BlacklistButton);
