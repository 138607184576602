import { PropsWithChildren, FC } from 'react';

import { VacancySearchItem } from 'lux/models/vacancySearch/vacancySearchItem.types';
import useBrandedSnippetDecoration from 'src/components/BrandedSnippet/hooks/useBrandedSnippetDecoration';
import { useSelector } from 'src/hooks/useSelector';

import BrandedSnippetContext from 'src/components/BrandedSnippet/context/BrandedSnippetContext';

type BrandedSnippetProviderProps = FC<PropsWithChildren<VacancySearchItem>>;

const BrandedSnippetProvider: BrandedSnippetProviderProps = ({ children, ...vacancy }) => {
    const isBrandedSnippetAllowed = useSelector((state) => state.isVacancyBrandedSnippetAllowed);
    const brandSnippet = vacancy.brandSnippet;
    const brandedSnippetDecoration = useBrandedSnippetDecoration({
        branding: brandSnippet,
        isBrandedSnippetAllowed,
    });
    const showBrandedSnippet = isBrandedSnippetAllowed && !!brandSnippet && !brandedSnippetDecoration.hasLoadingError;
    const value = showBrandedSnippet ? brandedSnippetDecoration : null;
    return <BrandedSnippetContext.Provider value={value}>{children}</BrandedSnippetContext.Provider>;
};

export default BrandedSnippetProvider;
