import classnames from 'classnames';

import { VSpacing } from '@hh.ru/magritte-ui';
import HSpacing from 'bloko/blocks/hSpacing';
import { BoxScaleSmallKindDefault, IconColor } from 'bloko/blocks/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { UserType } from 'lux/models/userType';
import { VacancyProperty } from 'lux/models/vacancyProperties';
import {
    VacancySearchItem as VacancySearchItemType,
    ACTIVITY_ONLINE,
} from 'lux/models/vacancySearch/vacancySearchItem.types';
import RespondedSuccess from 'src/components/Applicant/Response/RespondedSuccess';
import VacancySearchItemDebug from 'src/components/Debug/SearchDebug';
import ActionButtons from 'src/components/VacancySearchItem/ActionButtons';
import AdvMarkContainer from 'src/components/VacancySearchItem/AdvMarkContainer';
import BlacklistButton from 'src/components/VacancySearchItem/BlacklistButton';
import BottomRowInfo from 'src/components/VacancySearchItem/BottomRowInfo';
import Company from 'src/components/VacancySearchItem/Company';
import Compensation from 'src/components/VacancySearchItem/Compensation';
import Description from 'src/components/VacancySearchItem/Description';
import ExperienceAndRemote from 'src/components/VacancySearchItem/ExperienceAndRemote';
import Favorite from 'src/components/VacancySearchItem/Favorite';
import Labels from 'src/components/VacancySearchItem/Labels';
import MainButtons from 'src/components/VacancySearchItem/MainButtons';
import TopRowInfo from 'src/components/VacancySearchItem/TopRowInfo';
import VacancyName from 'src/components/VacancySearchItem/VacancyName';
import { useCardContainerClassNames } from 'src/components/VacancySearchItem/hooks/useCardContainerClassNames';
import Source from 'src/components/VacancySearchItem/types/Source';
import translation from 'src/components/translation';
import useMagritte from 'src/hooks/useMagritte';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import usePropertiesBundle from 'src/hooks/usePropertiesBundle';
import { useSelector } from 'src/hooks/useSelector';

import styles from './styles.less';

const TrlKeys = {
    movedToArchive: 'vacancySearch.vacancyCard.movedToArchive',
    archived: 'applicant.negotiations.vacancyArchived',
};

type VacancySearchItemProps = {
    vacancy: VacancySearchItemType;
    vacancySource: Source;
    hhtmFromLabel?: string;
    criteriaText?: string;
};

const VacancySearchItem: TranslatedComponent<VacancySearchItemProps> = ({
    vacancy,
    vacancySource,
    hhtmFromLabel,
    criteriaText,
    trls,
}) => {
    const isMagritte = useMagritte();
    const {
        vacancyId,
        '@click': advClickUrl,
        '@isAdv': isAdv,
        '@showContact': showContact,
        '@workSchedule': workSchedule,
        area,
        address,
        'hr-brand': hrBrand,
        name,
        employerManager,
        archived,
        compensation,
        workExperience,
        immediateRedirectUrl: vrImmediateRedirectUrl,
        chatWritePossibility,
        vacancyProperties,
        isVacancyOfTheDay,
        type,
        searchDebug,
        snippet,
        company,
        clickUrl,
        insider,
        online_users_count: onlineUsersCount,
        closedForApplicants,
    } = vacancy;
    const { wideCardContainerClassName, narrowCardContainerClassName } = useCardContainerClassNames();
    const propertiesBundle = usePropertiesBundle();
    const userType = useSelector((state) => state.userType);
    const isEmployerOnline = employerManager.latestActivity === ACTIVITY_ONLINE;
    const isEmployer = useSelector((state) => state.userType === UserType.Employer);
    const shouldShowAdv = isAdv && isEmployer;
    const employerManagerName = [
        employerManager['@lastName'],
        employerManager['@firstName'],
        employerManager['@middleName'],
    ]
        .filter(Boolean)
        .join(' ');

    const shouldShowFireLabel = Boolean(
        isVacancyOfTheDay ||
            vacancyProperties.calculatedStates?.[propertiesBundle]?.filteredPropertyNames.some((property) =>
                [VacancyProperty.HhSearchResultsLabel, VacancyProperty.ZpSearchResultsLabel].includes(property)
            )
    );
    const shouldShowLogo = Boolean(
        isVacancyOfTheDay ||
            vacancyProperties.calculatedStates?.[propertiesBundle]?.filteredPropertyNames.some((property) =>
                [VacancyProperty.HhSearchResultsLogo, VacancyProperty.ZpSearchResultsLogo].includes(property)
            )
    );
    const shouldDisableCompanyLink =
        useIsZarplataPlatform() &&
        !!vacancyProperties?.calculatedStates?.HH?.filteredPropertyNames.includes(VacancyProperty.HhAuction);
    const vacancyOfTheDayClickUrl = isVacancyOfTheDay && vacancy.clickUrl ? vacancy.clickUrl : null;
    const hasCompensationAndExperience = (compensation.from || compensation.to) && workExperience;

    const vacancyLinkProps = {
        vacancySource,
        hhtmFromLabel,
        criteriaText: criteriaText ?? '',
        vacancy,
    };

    const isAnonymousVacancy = vacancyProperties.calculatedStates?.[propertiesBundle]?.anonymous || false;

    const vacancyInfoElement = (
        <>
            {isMagritte ? (
                <ActionButtons
                    vacancyId={vacancyId}
                    employerId={company.id}
                    isAnonymousVacancy={isAnonymousVacancy}
                    isClosedVacancy={closedForApplicants}
                    clickUrl={clickUrl ?? ''}
                    isAdv={Boolean(isAdv)}
                    vacancySource={vacancySource}
                />
            ) : (
                <>
                    <Favorite
                        vacancyId={vacancyId}
                        employerId={company.id}
                        isClosedVacancy={closedForApplicants}
                        clickUrl={clickUrl ?? ''}
                        isAdv={Boolean(isAdv)}
                        vacancySource={vacancySource}
                    />
                    <div className={narrowCardContainerClassName}>
                        <BlacklistButton
                            vacancyId={vacancyId}
                            employerId={company.id}
                            isAnonymousVacancy={isAnonymousVacancy}
                        />
                    </div>
                </>
            )}

            <TopRowInfo onlineUsersCount={onlineUsersCount} advClickUrl={advClickUrl} shouldShowAdv={shouldShowAdv} />

            <AdvMarkContainer isAdv={isAdv}>
                <VacancyName
                    name={name}
                    vacancyLinkProps={vacancyLinkProps}
                    shouldShowFireLabel={shouldShowFireLabel}
                />
                {isMagritte ? <VSpacing default={8} /> : <VSpacing default={8} xs={12} />}

                <div className={wideCardContainerClassName}>
                    <div
                        className={classnames(
                            styles.compensationLabels,
                            isMagritte && styles.compensationLabelsMagritte
                        )}
                    >
                        <Compensation compensation={compensation} />
                        <ExperienceAndRemote workExperience={workExperience} workSchedule={workSchedule} />
                    </div>
                </div>
                <div className={narrowCardContainerClassName}>
                    <Compensation compensation={compensation} />
                    {hasCompensationAndExperience && <VSpacing default={isMagritte ? 8 : 12} />}
                    <div
                        className={classnames(
                            styles.compensationLabels,
                            isMagritte && styles.compensationLabelsMagritte
                        )}
                    >
                        <ExperienceAndRemote workExperience={workExperience} workSchedule={workSchedule} />
                    </div>
                </div>
            </AdvMarkContainer>
            <Company
                company={company}
                vacancySource={vacancySource}
                hrBrand={hrBrand}
                insider={insider}
                area={area}
                address={address}
                advClickUrl={advClickUrl}
                isEmployerOnline={isEmployerOnline}
                shouldShowLogo={shouldShowLogo}
                shouldDisableCompanyLink={shouldDisableCompanyLink}
            />

            {isMagritte ? (
                <>
                    <Labels vacancy={vacancy} />
                    <Description snippet={snippet} highlightedShort={vacancySource === Source.VacancySearch} />
                </>
            ) : (
                <>
                    <Description snippet={snippet} highlightedShort={vacancySource === Source.VacancySearch} />
                    <Labels vacancy={vacancy} />
                </>
            )}
        </>
    );

    return (
        <div className={classnames(styles.vacancyCard, 'font-inter', { 'archived-vacancy-card': Boolean(archived) })}>
            <>
                {isMagritte ? <div className={styles.vacancyInfo}>{vacancyInfoElement}</div> : vacancyInfoElement}

                {!isMagritte && Boolean(archived) && (
                    <div data-qa="vacancy-serp__vacancy_archived" className={styles.vacancyArchived}>
                        <BoxScaleSmallKindDefault initial={IconColor.Gray50} />
                        <HSpacing base={2} />
                        <span className={styles.archivedText}>
                            <span className={narrowCardContainerClassName}>{trls[TrlKeys.archived]}</span>
                            <span className={wideCardContainerClassName}>{trls[TrlKeys.movedToArchive]}</span>
                        </span>
                    </div>
                )}
                <MainButtons
                    vacancyId={vacancyId}
                    vacancySource={vacancySource}
                    companyId={company.id}
                    showContact={showContact}
                    chatWritePossibility={chatWritePossibility}
                    vrImmediateRedirectUrl={vrImmediateRedirectUrl ?? ''}
                    vacancyType={type}
                    clickUrl={clickUrl ?? ''}
                    vacancyOfTheDayClickUrl={vacancyOfTheDayClickUrl}
                    address={address}
                    isAdv={Boolean(isAdv)}
                    isArchived={Boolean(archived)}
                    isAnonymousVacancy={isAnonymousVacancy}
                    employerManagerName={employerManagerName}
                />
                {searchDebug && <VacancySearchItemDebug {...searchDebug} />}
                {userType === UserType.Applicant && (
                    <div className="serp-item-link-disable">
                        <RespondedSuccess vacancyId={vacancyId} defaultView="search" isMagritteExp={isMagritte} />
                    </div>
                )}

                {!isMagritte && <BottomRowInfo advClickUrl={advClickUrl} shouldShowAdv={shouldShowAdv} />}
            </>
        </div>
    );
};

export default translation(VacancySearchItem);
