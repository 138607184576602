import { Text } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { MetroList as MetroListType } from 'src/components/Employer/VacanciesGroupsByRegion/VacancyGroupsTypes';
import MetroPoint from 'src/components/MetroPoint';
import FakeMagrittePrimaryText from 'src/components/VacancySearchItem/FakeMagrittePrimaryText';
import translation from 'src/components/translation';
import useMagritte from 'src/hooks/useMagritte';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

type MetroListProps = {
    metroList: MetroListType;
};

const TrlKeys = {
    moreMetro: 'search.result.item.metro.more',
};

const MetroList: TranslatedComponent<MetroListProps> = ({ metroList, trls }) => {
    const isMagritte = useMagritte();
    if (metroList.length === 0) {
        return null;
    }

    if (isMagritte) {
        return (
            <>
                <MetroPoint name={metroList[0].name} color={metroList[0].line.color} small isVacancyCard />
                {metroList.length > 1 && (
                    <>
                        <Text Element="span" typography="label-3-regular">
                            {NON_BREAKING_SPACE}
                            {trls[TrlKeys.moreMetro]}
                            {NON_BREAKING_SPACE}
                            {metroList.length - 1}
                            {NON_BREAKING_SPACE}
                        </Text>

                        {metroList.map((metro, index) => {
                            if (index === 0) {
                                return null;
                            }
                            return (
                                <MetroPoint
                                    key={metro['@id']}
                                    color={metroList[index].line.color}
                                    small
                                    isVacancyCard
                                />
                            );
                        })}
                    </>
                )}
            </>
        );
    }

    return (
        <>
            <MetroPoint name={metroList[0].name} color={metroList[0].line.color} small isVacancyCard />
            {metroList.length > 1 && (
                <>
                    <FakeMagrittePrimaryText Element="span">
                        {NON_BREAKING_SPACE}
                        {trls[TrlKeys.moreMetro]}
                        {NON_BREAKING_SPACE}
                        {metroList.length - 1}
                        {NON_BREAKING_SPACE}
                    </FakeMagrittePrimaryText>

                    {metroList.map((metro, index) => {
                        if (index === 0) {
                            return null;
                        }
                        return (
                            <MetroPoint key={metro['@id']} color={metroList[index].line.color} small isVacancyCard />
                        );
                    })}
                </>
            )}
        </>
    );
};

export default translation(MetroList);
