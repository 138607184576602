import { useCallback } from 'react';

import { Action } from '@hh.ru/magritte-ui';
import { HeartOutlinedSize24, HeartFilledSize24 } from '@hh.ru/magritte-ui/icon';
import ButtonBloko, { ButtonAppearance, ButtonScale } from 'bloko/blocks/button';
import { IconColor, HeartScaleMediumAppearanceFilled, HeartScaleMediumAppearanceOutlined } from 'bloko/blocks/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { getDataQa } from 'Modules/VacancyToFavorite';
import { UserType } from 'lux/models/userType';
import Source from 'src/components/VacancySearchItem/types/Source';
import translation from 'src/components/translation';
import useFavorite from 'src/hooks/useFavorite';
import useMagritte from 'src/hooks/useMagritte';
import { useSelector } from 'src/hooks/useSelector';
import { sendAdvSearchVacancyAnalytics, AdvAnalyticsTypes } from 'src/utils/sendAdvAnalytics';

import styles from './styles.less';

const TrlKeys = {
    addToFavorite: 'button.addToFavorites',
};

interface Props {
    vacancyId: number;
    employerId: number;
    isClosedVacancy: boolean;
    clickUrl: string;
    isAdv: boolean;
    vacancySource: Source;
}

const VacancyFavorite: TranslatedComponent<Props> = ({
    vacancyId,
    employerId,
    isClosedVacancy,
    clickUrl,
    isAdv,
    trls,
    vacancySource,
}) => {
    const isMagritte = useMagritte();
    const onFavoriteButtonClick = useCallback(
        (favoriteState: boolean) => {
            if (favoriteState) {
                sendAdvSearchVacancyAnalytics({ clickUrl, isAdv, analyticsType: AdvAnalyticsTypes.FavoriteButton });
            }
        },
        [clickUrl, isAdv]
    );

    const { isFavoriteVacancy, toggleIsFavoriteVacancy, isAvailableChangeFavoriteState } = useFavorite({
        onFavoriteStateChanged: onFavoriteButtonClick,
        vacancyId,
        employerId,
        isClosedVacancy,
        isSendUxFeedback: true,
    });
    const userType = useSelector((state) => state.userType);

    if (
        (!isAvailableChangeFavoriteState || userType !== UserType.Applicant) &&
        vacancySource !== Source.EmployerVacancy
    ) {
        return null;
    }

    if (isMagritte) {
        return (
            <Action
                onClick={toggleIsFavoriteVacancy}
                aria-label={trls[TrlKeys.addToFavorite]}
                data-qa={getDataQa(isFavoriteVacancy, true)}
                mode={isFavoriteVacancy ? 'primary' : 'secondary'}
                style={isFavoriteVacancy ? 'negative' : 'neutral'}
                icon={isFavoriteVacancy ? HeartFilledSize24 : HeartOutlinedSize24}
            />
        );
    }

    return (
        <div className={styles.favoriteContainer}>
            <ButtonBloko
                aria-label={trls[TrlKeys.addToFavorite]}
                appearance={ButtonAppearance.Flat}
                scale={ButtonScale.Large}
                data-qa={getDataQa(isFavoriteVacancy, true)}
                onClick={toggleIsFavoriteVacancy}
            >
                <div className="fit-icon-in-button-container">
                    {isFavoriteVacancy ? (
                        <HeartScaleMediumAppearanceFilled
                            initial={isFavoriteVacancy ? IconColor.Red60 : IconColor.Black}
                        />
                    ) : (
                        <HeartScaleMediumAppearanceOutlined
                            initial={isFavoriteVacancy ? IconColor.Red60 : IconColor.Black}
                        />
                    )}
                </div>
            </ButtonBloko>
        </div>
    );
};

export default translation(VacancyFavorite);
