import { FC } from 'react';
import classnames from 'classnames';

import { Text, VSpacingContainer, VSpacing } from '@hh.ru/magritte-ui';
import VSpacingBloko from 'bloko/blocks/vSpacing';
import VSpacingContainerBloko from 'bloko/blocks/vSpacing/VSpacingContainer';

import { VacancySnippet } from 'lux/models/vacancySearch/vacancySearchItem.types';
import AsyncHighlighter from 'src/components/Markup/AsyncHighlighter';
import FakeMagrittePrimaryText from 'src/components/VacancySearchItem/FakeMagrittePrimaryText';
import useMagritte from 'src/hooks/useMagritte';

import styles from './styles.less';

interface DescriptionProps {
    snippet?: VacancySnippet;
    highlightedShort?: boolean;
}

const Description: FC<DescriptionProps> = ({ snippet, highlightedShort }) => {
    const isMagritte = useMagritte();

    if (!snippet?.resp && !snippet?.req) {
        return null;
    }

    if (isMagritte) {
        return (
            <div className={classnames('g-user-content', styles.description)}>
                <VSpacing default={24} gteS={16} />
                <VSpacingContainer default={8}>
                    {snippet.resp && (
                        <Text typography="paragraph-3-regular" data-qa="vacancy-serp__vacancy_snippet_responsibility">
                            <AsyncHighlighter short={highlightedShort}>{snippet.resp}</AsyncHighlighter>
                        </Text>
                    )}
                    {snippet.req && (
                        <Text typography="paragraph-3-regular" data-qa="vacancy-serp__vacancy_snippet_requirement">
                            <AsyncHighlighter short={highlightedShort}>{snippet.req}</AsyncHighlighter>
                        </Text>
                    )}
                </VSpacingContainer>
            </div>
        );
    }

    return (
        <div className="g-user-content">
            <VSpacingBloko base={4} xs={6} />
            <VSpacingContainerBloko base={2}>
                {snippet.resp && (
                    <FakeMagrittePrimaryText dataQa="vacancy-serp__vacancy_snippet_responsibility">
                        <AsyncHighlighter short={highlightedShort}>{snippet.resp}</AsyncHighlighter>
                    </FakeMagrittePrimaryText>
                )}
                {snippet.req && (
                    <FakeMagrittePrimaryText dataQa="vacancy-serp__vacancy_snippet_requirement">
                        <AsyncHighlighter short={highlightedShort}>{snippet.req}</AsyncHighlighter>
                    </FakeMagrittePrimaryText>
                )}
            </VSpacingContainerBloko>
        </div>
    );
};

export default Description;
