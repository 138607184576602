import { FC } from 'react';

import { VSpacing, HSpacing, Text } from '@hh.ru/magritte-ui';
import VSpacingBloko from 'bloko/blocks/vSpacing';

import { Address } from 'src/components/Employer/VacanciesGroupsByRegion/VacancyGroupsTypes';
import Divider from 'src/components/VacancySearchItem/Divider';
import FakeMagrittePrimaryText from 'src/components/VacancySearchItem/FakeMagrittePrimaryText';
import VacancyMetroList from 'src/components/VacancySearchItem/MetroList';
import { useCardContainerClassNames } from 'src/components/VacancySearchItem/hooks/useCardContainerClassNames';
import useMagritte from 'src/hooks/useMagritte';

type VacancyAddressProps = {
    area?: {
        '@id': number;
        name: string;
    };
    address?: Address;
};

const VacancyAddress: FC<VacancyAddressProps> = ({ area, address }) => {
    const isMagritte = useMagritte();
    const metroList = address?.metroStations?.metro?.filter((metro) => metro['@cityId'] === area?.['@id']) ?? [];
    const { wideCardContainerClassName, narrowCardContainerClassName } = useCardContainerClassNames();

    if (isMagritte) {
        const areaNameElement = area && Boolean(area.name) && (
            <Text typography="label-3-regular" Element="span" data-qa="vacancy-serp__vacancy-address">
                {area.name}
            </Text>
        );
        return (
            <>
                <div className={narrowCardContainerClassName}>
                    <VSpacing default={12} />
                    {areaNameElement}
                    {metroList.length > 0 && (
                        <>
                            <VSpacing default={4} />
                            <VacancyMetroList metroList={metroList} />
                        </>
                    )}
                </div>

                <div className={wideCardContainerClassName}>
                    <VSpacing default={8} />
                    {areaNameElement}
                    {metroList.length > 0 && (
                        <>
                            <HSpacing default={12} />
                            <VacancyMetroList metroList={metroList} />
                        </>
                    )}
                </div>
            </>
        );
    }

    return (
        <>
            <div className={narrowCardContainerClassName}>
                <VSpacingBloko base={2} />

                {Boolean(area?.name) && (
                    <FakeMagrittePrimaryText Element="span" dataQa="vacancy-serp__vacancy-address_narrow">
                        {area?.name}
                    </FakeMagrittePrimaryText>
                )}
                {metroList.length > 0 && (
                    <>
                        <VSpacingBloko base={1} />
                        <VacancyMetroList metroList={metroList} />
                    </>
                )}
            </div>

            <div className={wideCardContainerClassName}>
                <VSpacingBloko base={2} />

                {Boolean(area?.name) && (
                    <FakeMagrittePrimaryText Element="span" dataQa="vacancy-serp__vacancy-address">
                        {area?.name}
                    </FakeMagrittePrimaryText>
                )}
                {metroList.length > 0 && (
                    <>
                        <Divider />
                        <VacancyMetroList metroList={metroList} />
                    </>
                )}
            </div>
        </>
    );
};

export default VacancyAddress;
