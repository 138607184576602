import { ModalTitle, ModalHeader } from 'bloko/blocks/modal';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { ResponseStatusResume } from 'lux/models/applicantVacancyResponseStatuses';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import BottomSheetHeader from 'src/components/VacancyResponsePopup/BottomSheet/Header';

const TrlKeys = {
    default: 'vacancy.response.popup.title',
    repeat: 'vacancy.response.popup.title.again',
    questionResumes: 'vacancy.questionResponse.popup.title',
    questionResumesDescription: 'vacancy.questionResponse.popup.description',
};

interface PopupHeaderProps {
    isXs: boolean;
    isQuestionResponse?: boolean;
    selectedResume: ResponseStatusResume;
    onXsClick: () => void;
    resumesCount: number;
    vacancyId: number;
}

const PopupHeader: TranslatedComponent<PopupHeaderProps> = ({
    trls,
    isXs,
    isQuestionResponse,
    selectedResume,
    onXsClick,
    resumesCount,
    vacancyId,
}) => {
    const responseStatus = useSelector((state) => state.applicantVacancyResponseStatuses[vacancyId]);
    const isFirstResponse = responseStatus.usedResumeIds.length === 0;
    const vacancyName = responseStatus.shortVacancy.name;
    return (
        <>
            {isXs ? (
                <BottomSheetHeader
                    isQuestionResponse={isQuestionResponse}
                    resume={selectedResume}
                    onClick={onXsClick}
                    allowClick={resumesCount > 1}
                    vacancyId={vacancyId}
                />
            ) : (
                <ModalHeader outlined>
                    <ModalTitle>
                        {isQuestionResponse
                            ? trls[TrlKeys.questionResumes]
                            : trls[isFirstResponse ? TrlKeys.default : TrlKeys.repeat]}
                    </ModalTitle>
                    <div>{isQuestionResponse ? trls[TrlKeys.questionResumesDescription] : `«${vacancyName}»`}</div>
                </ModalHeader>
            )}
        </>
    );
};

export default translation(PopupHeader);
