import { Tag, VSpacing } from '@hh.ru/magritte-ui';
import VSpacingBloko from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import { VacancySearchItem } from 'lux/models/vacancySearch/vacancySearchItem.types';
import Label, { LabelType, LabelColor } from 'src/components/Label';
import translation from 'src/components/translation';
import useMagritte from 'src/hooks/useMagritte';

import { useVacancyLabels } from 'src/components/VacancySearchItem/Labels/useVacancyLabels';

import styles from './styles.less';

const TrlKeys = {
    skillsPercentage: 'label.skillsPercentage',
    [LabelType.NoResume]: 'label.noResume',
    [LabelType.BeFirst]: 'label.fewResponses',
};

type LabelsProps = {
    vacancy: VacancySearchItem;
};

const Labels: TranslatedComponent<LabelsProps> = ({ vacancy, trls }) => {
    const {
        isFewResponsesLabelShown,
        isNoResumeLabelShown,
        isKeySkillPercentLabelShown,
        matchedKeySkillAnalyticsData,
        isAnyLabelShown,
    } = useVacancyLabels({
        vacancy,
    });
    const isMagritte = useMagritte();

    if (!isAnyLabelShown) {
        return null;
    }

    if (isMagritte) {
        return (
            <>
                <VSpacing default={24} gteS={16} />
                <div className={styles.labels}>
                    {isKeySkillPercentLabelShown && (
                        <Tag style="positive" data-qa={`vacancy-label-${LabelType.SkillsPercentage}`}>
                            {formatToReactComponent(trls[TrlKeys.skillsPercentage], {
                                '{0}': matchedKeySkillAnalyticsData?.matchedKeySkillPercent ?? '',
                            })}
                        </Tag>
                    )}
                    {isNoResumeLabelShown && (
                        <Tag style="positive" data-qa={`vacancy-label-${LabelType.NoResume}`}>
                            {trls[TrlKeys[LabelType.NoResume]]}
                        </Tag>
                    )}
                    {isFewResponsesLabelShown && (
                        <Tag style="positive" data-qa={`vacancy-label-${LabelType.BeFirst}`}>
                            {trls[TrlKeys[LabelType.BeFirst]]}
                        </Tag>
                    )}
                </div>
            </>
        );
    }

    return (
        <>
            <VSpacingBloko base={4} xs={6} />
            <div className={styles.labels}>
                {isKeySkillPercentLabelShown && (
                    <Label
                        type={LabelType.SkillsPercentage}
                        dataQa={`vacancy-label-${LabelType.SkillsPercentage}`}
                        color={LabelColor.LightGreenRedesign}
                        Content={formatToReactComponent(trls[TrlKeys.skillsPercentage], {
                            '{0}': matchedKeySkillAnalyticsData?.matchedKeySkillPercent ?? '',
                        })}
                    />
                )}
                {isNoResumeLabelShown && (
                    <Label
                        type={LabelType.NoResume}
                        dataQa={`vacancy-label-${LabelType.NoResume}`}
                        color={LabelColor.LightGreenRedesign}
                    />
                )}
                {isFewResponsesLabelShown && (
                    <Label
                        type={LabelType.BeFirst}
                        dataQa={`vacancy-label-${LabelType.BeFirst}`}
                        color={LabelColor.LightGreenRedesign}
                    />
                )}
            </div>
        </>
    );
};

export default translation(Labels);
