import { useState, MutableRefObject } from 'react';
import classnames from 'classnames';

import Button, { ButtonAppearance, ButtonScale } from 'bloko/blocks/button';
import Menu, { MenuPlacement, MenuItem } from 'bloko/blocks/drop/Menu';
import { EllipsisScaleMediumKindHorizontal, IconColor } from 'bloko/blocks/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { useCardContainerClassNames } from 'src/components/VacancySearchItem/hooks/useCardContainerClassNames';
import Source from 'src/components/VacancySearchItem/types/Source';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import UserLabel from 'src/utils/constants/userLabels';

import styles from './styles.less';

type Props = {
    showContact: boolean;
    vacancyId: number;
    handleContactsClickRef: MutableRefObject<{ handleClick?: () => void }>;
    vacancySource: Source;
};

const TrlKeys = {
    contacts: 'vacancy.search.contacts',
    chat: 'vacancySearchResults.chat',
};

// Кнопка с многоточием видна только на xs после отклика
const EtcButton: TranslatedComponent<Props> = ({
    showContact,
    vacancyId,
    handleContactsClickRef,
    vacancySource,
    trls,
}) => {
    const { narrowCardContainerClassName } = useCardContainerClassNames();

    const [isMenuVisible, setIsMenuVisible] = useState(false);

    const userLabels = useSelector((state) => state.userLabelsForVacancies)[vacancyId];
    const isResponded = userLabels?.includes(UserLabel.Response);
    const isInvited = userLabels?.includes(UserLabel.Invite);
    const isDiscarded = userLabels?.includes(UserLabel.Discard);
    const showWorkflowStatus = isDiscarded || isInvited || isResponded;

    if (!showWorkflowStatus) {
        return null;
    }

    const openContacts = () => {
        setIsMenuVisible(false);

        setTimeout(() => {
            handleContactsClickRef.current.handleClick?.();
        }, 200);
    };

    const toggleIsMenuVisible = () => {
        setIsMenuVisible((oldIsMenuVisible) => !oldIsMenuVisible);
    };

    const chatLinkHref = `/negotiations/gotochat?vacancyId=${vacancyId}&hhtmFromLabel=${vacancySource}`;

    return (
        <div className={classnames(narrowCardContainerClassName, styles.etcButtonContainer)}>
            <Menu
                show={isMenuVisible}
                onClose={() => setIsMenuVisible(false)}
                placement={MenuPlacement.BottomStart}
                render={() => {
                    return (
                        <>
                            {showContact && <MenuItem onClick={openContacts}>{trls[TrlKeys.contacts]}</MenuItem>}
                            <MenuItem href={chatLinkHref} target="_blank">
                                {trls[TrlKeys.chat]}
                            </MenuItem>
                        </>
                    );
                }}
            >
                <Button appearance={ButtonAppearance.Flat} scale={ButtonScale.Large} onClick={toggleIsMenuVisible}>
                    <div className="fit-icon-in-button-container">
                        <EllipsisScaleMediumKindHorizontal initial={IconColor.Black} />
                    </div>
                </Button>
            </Menu>
        </div>
    );
};

export default translation(EtcButton);
