import { Fragment } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { H2Section } from 'bloko/blocks/header';
import { ModalContent, ModalHeader } from 'bloko/blocks/modal';
import Radio from 'bloko/blocks/radio';
import Text, { TextImportance } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import numberFormatter from 'bloko/common/numberFormatter';

import { ResponseStatusResume } from 'lux/models/applicantVacancyResponseStatuses';
import Currency from 'src/components/Currency';
import translation from 'src/components/translation';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

import ResumeWithPhoto from 'src/components/VacancyResponsePopup/BottomSheet/ResumeWithPhoto';

import styles from './resume-select-form.less';

const TrlKeys = {
    resumes: 'vacancy.response.popup.resumes',
    questionResumesTitle: 'vacancy.questionResponse.popup.resumes',
    continue: 'employer.vacancy.create.button.continue',
};

interface ResumeSelectFormProps {
    selectedResume: ResponseStatusResume;
    resumes: ResponseStatusResume[];
    onClose: () => void;
    setSelectedResume: (value: ResponseStatusResume) => void;
    isQuestionResponse?: boolean;
    vacancyId: number;
}

const ResumeSelectForm: TranslatedComponent<ResumeSelectFormProps> = ({
    selectedResume,
    resumes,
    onClose,
    setSelectedResume,
    vacancyId,
    isQuestionResponse,
    trls,
}) => {
    const onResumeClick = (resume: ResponseStatusResume) => {
        setSelectedResume(resume);
        Analytics.sendHHEventButtonClick('response_resume_selected', {
            resumeHash: resume._attributes.hash,
            vacancyId,
            resumesCount: resumes.length,
        });
        onClose();
    };
    return (
        <>
            <ModalHeader>
                <H2Section>{trls[isQuestionResponse ? TrlKeys.questionResumesTitle : TrlKeys.resumes]}</H2Section>
                <VSpacing base={2} />
            </ModalHeader>
            <ModalContent>
                {resumes.map((resume, index) => {
                    const area = resume.area?.[0]?.title;
                    const salary = resume.salary?.[0];
                    return (
                        <Fragment key={resume.id}>
                            <VSpacing base={index > 0 ? 4 : 2} />
                            <Radio
                                name="resume"
                                defaultChecked={resume.id === selectedResume.id}
                                onClick={() => onResumeClick(resume)}
                                reversed
                            >
                                <ResumeWithPhoto resume={resume}>
                                    {resume.title?.[0]?.string}
                                    <VSpacing base={1} />
                                    <Text importance={TextImportance.Tertiary}>
                                        {area}
                                        {area && salary && ' • '}
                                        {salary && (
                                            <>
                                                {numberFormatter.format(String(salary.amount), {
                                                    groupSeparator: NON_BREAKING_SPACE,
                                                })}
                                                {NON_BREAKING_SPACE}
                                                <Currency value={salary.currency} />
                                            </>
                                        )}
                                    </Text>
                                </ResumeWithPhoto>
                            </Radio>
                            <VSpacing base={4} />
                            <div className={styles.divider} />
                        </Fragment>
                    );
                })}
            </ModalContent>
        </>
    );
};

export default translation(ResumeSelectForm);
